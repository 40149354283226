import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { COLOR_CONSTANTS, radius } from 'theme'
import { Flex, Grid } from 'components/Layout'
import { H3, Text } from 'components/Typography'
import LocalImage from 'components/LocalImage'

import logoHorizontalLight from 'static/images/brand-assets/logos/logo-horizontal-light.svg'
import logoVerticalLight from 'static/images/brand-assets/logos/logo-vertical-light.svg'
import textLogoLight from 'static/images/brand-assets/logos/text-logo-light.svg'
import iconLogoLight from 'static/images/brand-assets/logos/icon-logo-light.svg'
import logoHorizontalDark from 'static/images/brand-assets/logos/logo-horizontal-dark.svg'
import logoVerticalDark from 'static/images/brand-assets/logos/logo-vertical-dark.svg'
import textLogoDark from 'static/images/brand-assets/logos/text-logo-dark.svg'
import iconLogoDark from 'static/images/brand-assets/logos/icon-logo-dark.svg'

import logoHorizontalLightPng from 'static/images/brand-assets/logos/logo-horizontal-light.png'
import logoVerticalLightPng from 'static/images/brand-assets/logos/logo-vertical-light.png'
import textLogoLightPng from 'static/images/brand-assets/logos/text-logo-light.png'
import iconLogoLightPng from 'static/images/brand-assets/logos/icon-logo-light.png'
import logoHorizontalDarkPng from 'static/images/brand-assets/logos/logo-horizontal-dark.png'
import logoVerticalDarkPng from 'static/images/brand-assets/logos/logo-vertical-dark.png'
import textLogoDarkPng from 'static/images/brand-assets/logos/text-logo-dark.png'
import iconLogoDarkPng from 'static/images/brand-assets/logos/icon-logo-dark.png'

import DownloadButton from './DownloadButton'
import HoverBlockComponent from './HoverBlockComponent'

const StyledGrid = styled(Grid)`
  justify-items: center;
`

const StyledCardDownload = styled.button`
  border: none;
  position: relative;
  background: inherit;
  transition: all 0.5s ease;
  overflow: hidden;
  cursor: pointer;
  min-width: 100%;
  border-radius: ${radius.xl};

  &:active {
    transform: translateY(-3px);
  }
`

const StyledCard = styled(Flex)`
  min-width: 100%;
  border-radius: ${radius.xl};
  border: ${(props) => (props.background === 'light' ? `1px solid ${COLOR_CONSTANTS.PALE_CORNFLOWER_BLUE}` : 'none')};
  background: ${(props) => (props.background === 'light' ? COLOR_CONSTANTS.WHITE : COLOR_CONSTANTS.DENIM)};
  transition: all 0.5s ease;
`

const MOCK_LOGOS = [
  {
    id: 1,
    background: 'light',
    nameAndFormat: 'logo-horizontal-light.png',
    logo: logoHorizontalLight,
    png: logoHorizontalLightPng,
    alt: 'dark horizontal logo on light background',
    width: 177,
    height: 40,
  },
  {
    id: 2,
    background: 'light',
    nameAndFormat: 'logo-vertical-light.png',
    logo: logoVerticalLight,
    png: logoVerticalLightPng,
    alt: 'dark vertical logo on light background',
    width: 174,
    height: 111,
  },
  {
    id: 3,
    background: 'light',
    nameAndFormat: 'text-logo-light.png',
    logo: textLogoLight,
    png: textLogoLightPng,
    alt: 'dark text logo on light background',
    width: 174,
    height: 25,
  },
  {
    id: 4,
    background: 'light',
    nameAndFormat: 'icon-logo-light.png',
    logo: iconLogoLight,
    png: iconLogoLightPng,
    alt: 'dark icon logo on light background',
    width: 66,
    height: 66,
  },
  {
    id: 5,
    background: 'dark',
    nameAndFormat: 'logo-horizontal-dark.png',
    logo: logoHorizontalDark,
    png: logoHorizontalDarkPng,
    alt: 'light horizontal logo on dark background',
    width: 177,
    height: 40,
  },
  {
    id: 6,
    background: 'dark',
    nameAndFormat: 'logo-vertical-dark.png',
    logo: logoVerticalDark,
    png: logoVerticalDarkPng,
    alt: 'light vertical logo on dark background',
    width: 174,
    height: 111,
  },
  {
    id: 7,
    background: 'dark',
    nameAndFormat: 'text-logo-dark.png',
    logo: textLogoDark,
    png: textLogoDarkPng,
    alt: 'light text logo on dark background',
    width: 174,
    height: 25,
  },
  {
    id: 8,
    background: 'dark',
    nameAndFormat: 'icon-logo-dark.png',
    logo: iconLogoDark,
    png: iconLogoDarkPng,
    alt: 'light icon logo on dark background',
    width: 66,
    height: 66,
  },
]

const ArchivedAssetsPath = `/brand-assets/logos.zip`
const ArchiveNameAndFormat = 'Vista Social Logos.zip'

const SectionLogoSystem = ({ handleDownload }) => {
  return (
    <>
      <Flex width="100%" alignItems="flex-start" flexDirection={{ mobile: 'column', desktop: 'row' }}>
        <Flex justifyContent="space-between" maxWidth="100%" flexDirection="column">
          <H3 fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="left">
            <Text fontSize="3xl" as="span" fontWeight="bold" color="primary">
              Logo{' '}
            </Text>
            <Text fontSize="3xl" as="span" fontWeight="bold" color={COLOR_CONSTANTS.SALUTE}>
              System
            </Text>
          </H3>
          <DownloadButton
            onClick={handleDownload}
            label="Download logos"
            path={ArchivedAssetsPath}
            nameAndFormat={ArchiveNameAndFormat}
          />
          <Text mt="m" pt="s" color="secondaryText" fontSize="m" textAlign="left" fontWeight="normal">
            The Vista Social logo system is comprised of an icon and a wordmark. Whenever possible use the primary
            four-color horizontal logo lockup, unless placing on a background color that compromises contrast. If in
            doubt, err on the side of a single-color logo. Please do not alter the logos in any way. Click on any
            version to download a high resolution file.
          </Text>
        </Flex>
      </Flex>
      <StyledGrid
        gridTemplateColumns={{
          mobile: '1fr',
          mobileLarge: 'repeat(2, 1fr)',
          tablet: 'repeat(3, 1fr)',
          desktop: 'repeat(4, 1fr)',
        }}
        gridGap="l"
        mt="l"
        pt="s"
      >
        {MOCK_LOGOS.map(({ id, png, nameAndFormat, background, logo, height, alt }) => (
          <StyledCardDownload key={id} onClick={() => handleDownload(png, nameAndFormat)} boxShadow={false}>
            <StyledCard
              background={background}
              justifyContent="center"
              alignItems="center"
              height={{ mobile: '200px', tablet: '255px' }}
            >
              <LocalImage src={logo} mx="auto" height={height} alt={alt} />
            </StyledCard>
            <HoverBlockComponent />
          </StyledCardDownload>
        ))}
      </StyledGrid>
    </>
  )
}

SectionLogoSystem.propTypes = {
  handleDownload: PropTypes.func,
}

export default SectionLogoSystem
