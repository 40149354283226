import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { COLOR_CONSTANTS, radius } from 'theme'
import LocalImage from 'components/LocalImage'
import { Flex, Grid } from 'components/Layout'
import { H3, Text } from 'components/Typography'

import imageryItem1 from 'static/images/brand-assets/imagery/imagery-item-1.png'
import imageryItem2 from 'static/images/brand-assets/imagery/imagery-item-2.png'
import imageryItem3 from 'static/images/brand-assets/imagery/imagery-item-3.png'

import DownloadButton from './DownloadButton'
import HoverBlockComponent from './HoverBlockComponent'

const StyledImage = styled(LocalImage)`
  max-width: 100%;
  object-fit: cover;
`

const StyledCardDownload = styled.button`
  border: none;
  position: relative;
  background: inherit;
  box-shadow: ${(props) =>
    !props.boxShadow ? 'none' : '0px 2px 4px rgba(37, 64, 102, 0.08), 0px 8px 4px rgba(118, 139, 168, 0.14)'};
  transition: all 0.5s ease;
  border-radius: ${radius.xl};
  overflow: hidden;
  cursor: pointer;
  min-width: 100%;

  &:active {
    transform: translateY(-3px);
    box-shadow: 0px 0px 8px rgb(145 158 171 / 20%);
  }
`

const MOCK_IMAGERY = [
  {
    id: 1,
    image: imageryItem1,
    nameAndFormat: 'imagery-item-1.png',
  },
  {
    id: 2,
    image: imageryItem2,
    nameAndFormat: 'imagery-item-2.png',
  },
  {
    id: 3,
    image: imageryItem3,
    nameAndFormat: 'imagery-item-3.png',
  },
  {
    id: 4,
    image: imageryItem1,
    nameAndFormat: 'imagery-item-1.png',
  },
  {
    id: 5,
    image: imageryItem2,
    nameAndFormat: 'imagery-item-2.png',
  },
  {
    id: 6,
    image: imageryItem3,
    nameAndFormat: 'imagery-item-3.png',
  },
  {
    id: 7,
    image: imageryItem1,
    nameAndFormat: 'imagery-item-1.png',
  },
  {
    id: 8,
    image: imageryItem2,
    nameAndFormat: 'imagery-item-2.png',
  },
  {
    id: 9,
    image: imageryItem3,
    nameAndFormat: 'imagery-item-3.png',
  },
]

const ArchivedAssetsPath = `/brand-assets/imagery.zip`
const ArchiveNameAndFormat = 'Vista Social Imagery.zip'

const SectionImagery = ({ handleDownload }) => {
  return (
    <>
      <Flex
        width="100%"
        alignItems="flex-start"
        justifyContent={{ mobile: 'center', desktop: 'space-between' }}
        flexDirection={{ mobile: 'column', desktop: 'row' }}
      >
        <Flex justifyContent="space-between" maxWidth="100%" width="100%" flexDirection="column">
          <H3 fontWeight="bold" fontSize="3xl" color={COLOR_CONSTANTS.DENIM} textAlign="left">
            Imagery
          </H3>
          <DownloadButton
            onClick={handleDownload}
            label="Download images"
            path={ArchivedAssetsPath}
            nameAndFormat={ArchiveNameAndFormat}
          />
          <Text mt="m" pt="s" color="secondaryText" fontSize="m" textAlign="left" fontWeight="normal">
            We take pride in both our product and our culture. Click on any image to download a high resolution file.
          </Text>
        </Flex>
      </Flex>
      <Grid
        gridTemplateColumns={{ mobile: '1fr', mobileLarge: 'repeat(2, 1fr)', desktop: 'repeat(3, 1fr)' }}
        justifyItems={{ mobile: 'center' }}
        gridGap="l"
        mt="l"
        pt="s"
      >
        {MOCK_IMAGERY.map((image) => (
          <StyledCardDownload key={image.id} onClick={() => handleDownload(image.image, image.nameAndFormat)} boxShadow>
            <StyledImage
              src={image.image}
              alt="Vista Social archived screenshot"
              height={{ mobile: 'auto', desktop: '350px' }}
              width="100%"
            />
            <HoverBlockComponent />
          </StyledCardDownload>
        ))}
      </Grid>
    </>
  )
}

SectionImagery.propTypes = {
  handleDownload: PropTypes.func,
}

export default SectionImagery
