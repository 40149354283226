import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import Alert from 'react-s-alert'
import { COLOR_CONSTANTS, radius, colors } from 'theme'
import { ERROR_MESSAGE } from 'consts'
import { Flex, Grid } from 'components/Layout'
import { H3, Text } from 'components/Typography'

const StyledGrid = styled(Grid)`
  & > :nth-child(6) {
    grid-row: span 1;
  }

  & > :nth-child(7) {
    grid-row: span 1;
  }
`

const StyledCard = styled(Flex)`
  cursor: pointer;
  min-width: 100%;
  padding: 20px;
  background: ${(props) => props.background};
  grid-row: span 2;
  border-radius: ${radius.xl};
  transition: all 0.5s ease;

  &:hover {
    transform: translateY(-3px);
  }

  //TODO remove this @media
  @media (max-width: 1024px) {
    grid-row: span 1;
  }
`

const MOCK_PALETTE = [
  {
    id: 1,
    title: 'Primary Blue',
    titleColor: COLOR_CONSTANTS.WHITE,
    label: colors.primary,
    labelColor: COLOR_CONSTANTS.WHITE,
  },
  {
    id: 2,
    title: 'Title Text',
    titleColor: COLOR_CONSTANTS.WHITE,
    label: COLOR_CONSTANTS.SALUTE,
    labelColor: COLOR_CONSTANTS.WHITE,
  },
  {
    id: 3,
    title: 'Body Text',
    titleColor: COLOR_CONSTANTS.WHITE,
    label: colors.secondaryText,
    labelColor: COLOR_CONSTANTS.WHITE,
  },
  {
    id: 4,
    title: 'Stroke',
    titleColor: COLOR_CONSTANTS.DENIM,
    label: COLOR_CONSTANTS.PALE_CORNFLOWER_BLUE,
    labelColor: COLOR_CONSTANTS.WHITE,
  },
  {
    id: 5,
    title: 'Background',
    titleColor: COLOR_CONSTANTS.DENIM,
    label: COLOR_CONSTANTS.SALT,
    labelColor: COLOR_CONSTANTS.SAPPHIRE,
  },
  {
    id: 6,
    title: 'Light Blue',
    titleColor: COLOR_CONSTANTS.DENIM,
    label: COLOR_CONSTANTS.BABY_BLUE_EYES,
    labelColor: COLOR_CONSTANTS.WHITE,
  },
  {
    id: 7,
    title: 'Splash',
    titleColor: COLOR_CONSTANTS.DENIM,
    label: '#FFF857',
    labelColor: COLOR_CONSTANTS.SAPPHIRE,
  },
]

const TIMEOUT_ALERT = 1000

const SectionColorPalette = () => {
  const [colorPalette, setColorPalette] = useState([...MOCK_PALETTE])

  const handleClickCopyText = async ({ text, index }) => {
    try {
      await navigator.clipboard.writeText(text)
      Alert.success(`Color has been copied.`, { timeout: TIMEOUT_ALERT })

      colorPalette[index].showCopied = true
      setColorPalette([...colorPalette])

      setTimeout(() => {
        colorPalette[index].showCopied = false
        setColorPalette([...colorPalette])
      }, TIMEOUT_ALERT)
    } catch (err) {
      Alert.success(ERROR_MESSAGE, { timeout: 5000 })
    }
  }

  return (
    <>
      <Flex width="100%" alignItems="flex-start" flexDirection={{ mobile: 'column', desktop: 'row' }}>
        <Flex justifyContent="space-between" maxWidth={{ mobile: '100%', desktop: '800px' }} flexDirection="column">
          <H3 fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="left">
            <Text fontSize="3xl" as="span" fontWeight="bold" color="primary">
              Color{' '}
            </Text>
            <Text fontSize="3xl" as="span" fontWeight="bold" color={COLOR_CONSTANTS.SALUTE}>
              palette
            </Text>
          </H3>
          <Text mt="m" pt="s" color="secondaryText" fontSize="m" textAlign="left" fontWeight="normal">
            The Vista color palette features a flexible range of vibrant colors, anchored by our primary blue. Click on
            any swatch to copy it to your clipboard.
          </Text>
        </Flex>
      </Flex>
      <StyledGrid
        mt="l"
        pt="s"
        gridGap="l"
        gridTemplateColumns={{ mobile: '1fr', mobileLarge: 'repeat(2, 1fr)', desktop: 'repeat(3, 1fr)' }}
        gridTemplateRows={{ mobile: '1fr', mobileLarge: '1fr', desktop: 'repeat(4, 99px)' }}
      >
        {colorPalette.map(({ id, label, title, titleColor, labelColor, showCopied = false }, index) => (
          <Fragment key={id}>
            <StyledCard
              onClick={() => handleClickCopyText({ text: label, index })}
              background={label}
              justifyContent={showCopied ? 'center' : 'flex-start'}
              alignItems={showCopied ? 'center' : 'flex-start'}
              flexDirection="column"
            >
              {showCopied ? (
                <Text color={titleColor} fontSize="m" fontWeight="bold">
                  Copied!
                </Text>
              ) : (
                <Fragment>
                  <Text color={titleColor} fontSize="xxl" fontWeight="bold">
                    {title}
                  </Text>
                  <Text color={labelColor} fontSize="m" fontWeight="medium">
                    {label}
                  </Text>
                </Fragment>
              )}
            </StyledCard>
          </Fragment>
        ))}
      </StyledGrid>
    </>
  )
}

export default SectionColorPalette
