import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { CONTAINER_MAX_WIDTH } from 'consts'
import { Flex } from 'components/Layout'
import Container from 'components/Container'

import SectionLogoSystem from './SectionLogoSystem'
import SectionColorPalette from './SectionColorPalette'
import SectionImagery from './SectionImagery'

const Wrapper = styled(Flex)`
  overflow-x: hidden;
`

const StyledContainer = styled(Container)`
  margin: 0 auto;
`

const renderBrandAssetsSection = (section, handleDownload) => {
  switch (section) {
    case 'logos':
      return <SectionLogoSystem handleDownload={handleDownload} />
    case 'palette':
      return <SectionColorPalette />
    // case 'images':
    //   return <SectionImagery handleDownload={handleDownload} />
    default:
      return <></>
  }
}

const BrandAssetsGridSection = ({ section, handleDownload }) => {
  return (
    <Wrapper
      as="section"
      mt={{ mobile: 'l', desktop: 'xl' }}
      pt={{ mobile: 's', desktop: 'l' }}
      pb={{ mobile: 'l', desktop: 'xl' }}
      mb={{ mobile: 's', desktop: 0 }}
      flexDirection="column"
      alignItems="center"
      width="100%"
    >
      <StyledContainer
        pl={{ mobile: 'l', tablet: 'l' }}
        pr={{ mobile: 'l', tablet: 'l' }}
        maxWidth={CONTAINER_MAX_WIDTH}
        width="100%"
        height="100%"
      >
        {renderBrandAssetsSection(section, handleDownload)}
      </StyledContainer>
    </Wrapper>
  )
}

export default BrandAssetsGridSection

BrandAssetsGridSection.propTypes = {
  section: PropTypes.oneOf(['logos', 'palette', 'images']).isRequired,
  handleDownload: PropTypes.func.isRequired,
}
