import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { COLOR_CONSTANTS } from 'theme'
import { Text } from 'components/Typography'
import LocalImage from 'components/LocalImage'
import imageDoubleArrowRightBlue from 'static/images/home/double_arrow_right_blue.svg'

const StyledLinkText = styled(Text)`
  white-space: nowrap;
  text-transform: uppercase;
  cursor: pointer;
`

const DownloadButton = ({ label, onClick, path, nameAndFormat }) => {
  return (
    <StyledLinkText
      mt="s"
      mx="0"
      textAlign="left"
      onClick={() => onClick(path, nameAndFormat)}
      justifyContent={{ mobile: 'center', tablet: 'center', desktop: 'flex-end' }}
      alignItems="center"
    >
      <Text fontSize="m" textAlign="left" color={COLOR_CONSTANTS.COBALT} as="span">
        {label}
      </Text>
      <LocalImage mt="-5px" src={imageDoubleArrowRightBlue} />
    </StyledLinkText>
  )
}

export default DownloadButton

DownloadButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  path: PropTypes.any.isRequired,
  nameAndFormat: PropTypes.string.isRequired,
}
