import React from 'react'
import styled from 'styled-components'
import { COLOR_CONSTANTS } from 'theme'
import { Flex } from 'components/Layout'
import { Text } from 'components/Typography'

const StyledHoverBlock = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
  transition: all 0.5s ease;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`

const HoverBlockComponent = () => {
  return (
    <StyledHoverBlock alignItems="center" justifyContent="center">
      <Text fontSize="l" textAlign="left" color={COLOR_CONSTANTS.WHITE} as="span">
        Download
      </Text>
    </StyledHoverBlock>
  )
}

export default HoverBlockComponent
