import React from 'react'
import Alert from 'react-s-alert'
import styled from 'styled-components'
import App from 'App'
import { COLOR_CONSTANTS, space } from 'theme'
import { CONTAINER_MAX_WIDTH, ERROR_MESSAGE } from 'consts'
import SEO from 'components/SEO'
import Container from 'components/Container'
import LocalImage from 'components/LocalImage'
import Footer from 'components/Footer'
import { Flex, Grid } from 'components/Layout'
import { H2, H1 } from 'components/Typography'
import Button from 'components/Button'

import imageHeader from 'static/images/brand-assets/header_picture.webp'

import BrandAssetsGridSection from './components/BrandAssetsGridSection'
import SocialMediasSection from '../Home/components/SocialMediasSection'

// const ArchivedAssetsPath = `/brand-assets/assets.zip`
const ArchivedAssetsPath = `/brand-assets/logos.zip`
const ArchiveNameAndFormat = 'Vista Social Assets.zip'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  overflow-x: hidden;
`

const StyledHeaderWrapper = styled(Wrapper)`
  overflow: hidden;
`

const StyledButtonGradient = styled(Button.Gradient)`
  height: 52px;
  box-shadow: 0px 18px 24px -8px rgba(36, 130, 253, 0.32);
  cursor: pointer;
  padding: ${space.m} ${space.l};
`

const sections = [
  { id: 1, name: 'logos' },
  { id: 2, name: 'palette' },
  { id: 3, name: 'images' },
]

const BrandAssets = () => {
  const handleDownload = (filePath, nameAndFormat) => {
    try {
      const link = document.createElement('a')
      link.href = filePath
      link.download = `${nameAndFormat}`
      link.click()
      link.remove()
      Alert.success(`File has been downloaded.`, { timeout: 5000 })
    } catch (err) {
      Alert.error(ERROR_MESSAGE, { timeout: 5000 })
    }
  }

  return (
    <App fullHeader>
      <SEO
        title="Brand Assets"
        description="Consistency builds a brand. Here you can find our core brand assets: logo and colors along with some basic guidelines, for your ease of use."
        path="/brand-assets/"
      />
      <StyledHeaderWrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex flexDirection="column" alignItems="center">
            <Grid
              mt="xl"
              gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
              gridGap="l"
              width="100%"
              alignItems="center"
            >
              <Flex
                width="100%"
                justifyContent="center"
                alignItems="center"
                maxWidth="448px"
                mx={{ mobile: 'auto', desktop: 0 }}
              >
                <Flex flexDirection="column">
                  <Flex width="100%" justifyContent="center" flexDirection="column">
                    <Flex flexDirection={{ mobile: 'row', desktop: 'column' }} flexWrap="wrap" justifyContent="center">
                      <H1
                        fontSize="6xl"
                        fontWeight="bold"
                        color={COLOR_CONSTANTS.DENIM}
                        textAlign={{ mobile: 'center', desktop: 'left' }}
                      >
                        Brand Assets
                      </H1>
                    </Flex>
                    <H2
                      mt="m"
                      pt="s"
                      color="secondaryText"
                      fontSize="l"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                      fontWeight="normal"
                      maxWidth="300px"
                    >
                      Consistency builds a brand. Here you can find our core brand assets: logo and colors along with
                      some basic guidelines, for your ease of use.
                    </H2>
                    <Flex
                      mt="m"
                      pt="s"
                      justifyContent={{ mobile: 'center', tablet: 'center', desktop: 'flex-start' }}
                      onClick={() => handleDownload(ArchivedAssetsPath, ArchiveNameAndFormat)}
                    >
                      <StyledButtonGradient>Download Assets</StyledButtonGradient>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                width="100%"
                justifyContent={{ mobile: 'center', desktop: 'flex-start' }}
                mt={{ mobile: 'l', desktop: 0 }}
              >
                <LocalImage src={imageHeader} width="100%" maxWidth="560px" alt="Brand Assets Vista Social" />
              </Flex>
            </Grid>
          </Flex>
        </Container>
      </StyledHeaderWrapper>
      {sections.map((section) => (
        <BrandAssetsGridSection key={section.id} section={section.name} handleDownload={handleDownload} />
      ))}
      <SocialMediasSection />
      <Footer />
    </App>
  )
}

export default BrandAssets
